import Vue from "vue";
import axios from "axios";
import VueCountryCode from "vue-country-code";
import vueCountryRegionSelect from "vue-country-region-select";
import Vtip from "vtip";
import "vtip/lib/index.min.css";
import Viewer from "v-viewer";
import "viewerjs/dist/viewer.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/base";
import "./plugins/chartist";
import "./plugins/vee-validate";
import "./plugins/vue-world-map";
import vuetify from "./plugins/vuetify";
import Message from "./components/Message/index.js";
import focus from "@/util/focus";
import usev2 from "@/util/v2";

import fabric from "fabric";
import VueGtag from "vue-gtag";
// import { setOptions, bootstrap } from 'vue-gtag';

// import Vuetify from 'vuetify/lib'
// import { ripple } from 'vuetify/lib/directives'
import i18n from "./i18n";

// 引用公共js
import common from "../Common/common.js";
import "../Common/apiHa";
import { get, post } from "../Common/api";

import confirm from "./util/confirm";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "./assets/iconfont/iconfont.css";
// import './assets/css/global.scss'
import "./assets/css/all.scss";

import Swiper, { Pagination, Navigation, Autoplay } from "swiper"; // js 模块
import "swiper/swiper-bundle.css"; // css 模块
import VueWorker from "vue-worker";
// import moment from 'moment'
import moment from "moment-timezone";

// 防止v-html的xss攻击
import VueDOMPurifyHTML from "vue-dompurify-html";

import displayDesksOffice from "@/util/displayDesksOffice";

import md5 from "js-md5";
Vue.prototype.$usev2 = usev2;
Vue.prototype.$md5 = md5;
Vue.prototype.$gtag = VueGtag;

Vue.use(VueDOMPurifyHTML);

Vue.prototype.moment = moment;
Vue.use(VueWorker);
Swiper.use([Pagination, Navigation, Autoplay]);

Vue.prototype.$axios = axios;

Vue.prototype.$confirm = confirm;
//将方法挂载到Vue原型上
Vue.prototype.get = get;
Vue.prototype.post = post;

Vue.config.productionTip = false;
Vue.prototype.bus = new Vue();
Vue.use(common);
Vue.prototype.$message = Message;
Vue.use(VueCountryCode);
Vue.use(vueCountryRegionSelect);
Vue.use(Vtip.directive);

// 工具函数调用
Vue.prototype.$tip = Vtip.tip;
// Vue.use(Vuetify, {
//   directives: {
//     Ripple: ripple
//   }
// });
Vue.use(Viewer);

Vue.use(fabric);

Vue.use(function(){
    axios
    .get("m/ga")
    .then(res => {
      let gid = '';
      if(res.data.data.ga4_key){
        gid = res.data.data.ga4_key.id;
      }else{
        gid = res.data.data.id;
      }
      localStorage.setItem("googleAnaId",gid);
      Vue.use(VueGtag, {
        config: { id: gid },
      }, router);
      // let gid = res.data.data.id;
      // localStorage.setItem("googleAnaId",gid);
      // Vue.use(VueGtag, {
      //   // config: { id: 'G-7PWZBBP4Q0' },
      //   config: { id: gid },
      // }, router);
    })}
  );

Vue.prototype.getClientData = function() {
  return this.$axios({
    method: "GET",
    url: "cms/config"
  });
};
Vue.prototype.getClient = function() {
  //changeData是函数名
  const _this = this;
  axios
    .get("cms/config")
    .then(response => {
      let client = response.data.Client;
      client = client.replace(/ /g, "");
      client = client.charAt(0).toUpperCase() + client.slice(1);
      let cssFileName = `global${client}.scss`;
      console.log("cssFileName:", cssFileName);
      if (client) {
        import(`./assets/css/${cssFileName}`);
      } else {
        import("./assets/css/global.scss");
      }
      _this.$store.commit("setClient", response.data.Client);
      _this.version = response.data.Client;
    })
    .catch(err => {
      console.log(err);
    });
};

Vue.prototype.getConfig = function() {
  //changeData是函数名
  console.log("执行了main.js中的m/config");
  const _this = this;
  axios
    .get("m/config")
    .then(response => {
      // console.log('version', response);
      localStorage.config = JSON.stringify(response.data);
    })
    .catch(err => {
      console.log(err);
    });
};
Vue.prototype.getDomain = function() {
  //changeData是函数名
  console.log("get domain", window.location.host);
  if(!localStorage.orginDomain){
    localStorage.orginDomain = window.location.host;
    localStorage.orginDomain = 'https://sun-test-dot-cx-app-metlife.uc.r.appspot.com/';
  }
  
  // localStorage.variableDomain = window.location.host;

};
Vue.prototype.getConfigReturn = function() {
  //changeData是函数名
  console.log("执行了main.js中的getConfigReturn中的m/config");
  const _this = this;
  return axios.get("m/config");
};
// Vue.prototype.getUserInfo = function (token) { //changeData是函数名
//   localStorage.stoken = token;
//   const _this = this;
//   let url = "m/profile/";
//   axios
//     .get(url)
//     .then((response) => {
//       // console.log('profile', response);
//       localStorage.userEmail = response.data.email;
//       localStorage.userId = response.data.userId;
//       localStorage.userName = response.data.firstName + ' ' + response.data.lastName;
//       localStorage.image = response.data.image;
//       // console.log(123,token);
//       // _this.$router.push({
//       //   path: "/mec",
//       // }).catch(err => {
//       //   console.log(err);
//       // });
//     })
//     .catch((err) => {
//       console.log(err);
//     });
// };

Vue.prototype.getUserInfo = function(token) {
  //changeData是函数名
  console.log("==== 执行了getUserInfo=====");
  localStorage.stoken = token;
  const _this = this;
  let url = "m/profile/";
  axios
    .get(url)
    .then(response => {
      console.log('profile', response);
      // localStorage.userEmail = response.data.email;
      localStorage.userId = response.data.userId;
      localStorage.userName = response.data.firstName + " " + response.data.lastName;
      localStorage.image = response.data.image;
      // _this.$store.state.avator = localStorage.image;
      // _this.setAvator(response.data.image);
      // console.log(123,token);
      // _this.$router.push({
      //   path: "/mec",
      // }).catch(err => {
      //   console.log(err);
      // });
    })
    .catch(err => {
      console.log(err);
    });
};

Vue.prototype.getUserAttendees = function(userId) {
  console.log('sunsunsuns',userId);
  const _this = this;
  let url = "m/attendees/?";
  let meetingIdMap = this.$route.query.meetingIdMap;
  if (meetingIdMap) {
    localStorage.meetingIdMap = meetingIdMap;
    url += 'meetingId='+localStorage.meetingIdMap+'&';
  }
  if(userId){
    url += 'attendeeId='+userId;
  }
  
  axios
    .get(url)
    .then((response) => {
      // console.log('sunsunsunsun', response);
      // console.log('sunsun','1231222423423');
      localStorage.setItem("userConfidanielDomain", response.data.confidanielDomain);
      _this.$store.commit("setConfidanielDomain", response.data.confidanielDomain);
      console.log(this.$store.state.confidanielDomain ,localStorage.getItem('userConfidanielDomain'),'sunsuns');
      // localStorage.setItem('userConfidanielDomain') = response.data.confidanielDomain;
      // localStorage.userId = response.data.userId;
      // localStorage.userName = response.data.firstName + ' ' + response.data.lastName;
      // localStorage.image = response.data.image;
      // _this.$router.push({path: "/mec"}).catch(err => {
      //   console.log(err);
      // });
    })
    .catch((err) => {
      console.log(err);
    });
};
Vue.prototype.getGid = function() {
  axios
  .get("m/ga")
  .then(res => {
    let gid = res.data.data.id;
    // let obj = {};
    // obj.disableScriptLoad = true;
    // obj.bootstrap = false;
    // obj.config = {};
    // obj.config.id = gid;
    console.log(gid,'啊神啊啊啊啊啊啊啊啊');
    // Vue.use(VueGtag, {
    //   config: { id: gid },
    // }, router);
    
    return gid;
  })
};



Viewer.setDefaults({
  Options: {
    inline: true,
    button: true,
    navbar: true,
    title: true,
    toolbar: true,
    tooltip: true,
    movable: true,
    zoomable: true,
    rotatable: true,
    scalable: true,
    transition: true,
    fullscreen: true,
    keyboard: true,
    url: "data-source"
  }
});

Vue.filter("moment", (value, formatString) => {
  formatString = formatString || "MMMM DD, YYYY";
  return moment(value).format(formatString);
});

router.beforeEach((to, from, next) => {
  console.log("==== to=====", to)
  console.log("==== from=====", from)
  store.commit("clearAxiosArr");
  if(to.name == 'GlobalDirectory'){
    // console.log('go to the globalDirectory',store.state.confidanielDomain,localStorage.userConfidanielDomain);
    if(JSON.parse(localStorage.config).features.indexOf("globalDirectory") < 0 && JSON.parse(localStorage.config).features.indexOf("global2Directory") < 0){
      // console.log('shena',JSON.parse(localStorage.config).features.indexOf("globalDirectory"),JSON.parse(localStorage.config));
      next({
        path: '/mec',
      });
      return
    }else{
      // console.log('this', localStorage.userConfidanielDomain,localStorage.meetingIdMap);
      if(localStorage.userConfidanielDomain == 'false' ){
        if(localStorage.meetingIdMap == ''  || localStorage.meetingIdMap == undefined){
          next({
            path: '/mec',
          });
          return
        }
      }
    }
   
  }
  if(to.name == 'Directory'){
    // console.log('Directory',JSON.parse(localStorage.config).features);
    if(JSON.parse(localStorage.config).features.indexOf("globalDirectory") > -1 || JSON.parse(localStorage.config).features.indexOf("global2Directory") > -1){
      next({
        path: '/mec',
      });
      return
    }
  }
  // 用一条链接直接跳转到webapp,检测一下当前链接有无meetingId和jumpToBooking
  if (to.query.meetingIdMap && to.name == "LoginEmail") {
    localStorage.setItem("meetingIdMap", to.query.meetingIdMap);
  }
  if (from.query.meetingIdMap && from.name == "LoginEmail") {
    localStorage.setItem("meetingIdMap", from.query.meetingIdMap);
  }
  if (to.query.jumpToBooking == "true" && to.name == "LoginEmail") {
    console.log("走1==========================");
    // 从cms跳转过来，如果url参数jumpToBooking和bookingType不为空，bookingType为Desks或Meetings，则跳转到booking的时候bookingType显示是Meeting或Desks
    if (to.query.bookingType) {
      if (to.query.bookingType == "Desks" || to.query.bookingType == "desks") {
        // 不同的client叫法不同，这里区分一下
        // if (localStorage.getItem('client') == 'NatWest' || localStorage.getItem('client') == 'Clorox' || localStorage.getItem('client') == 'WarnerMedia' || localStorage.getItem('client') == 'CX Campus'|| localStorage.getItem('client') == 'Rivian'|| localStorage.getItem('client') == 'DoorDash') {
        if (displayDesksOffice) {
          store.commit("bookingType/setBookingType", "Desks / Offices");
        } else {
          store.commit("bookingType/setBookingType", "Desks");
        }
      } else if (to.query.bookingType == "Meetings" || to.query.bookingType == "meetings") {
        store.commit("bookingType/setBookingType", "Meetings");
      }
    }
    localStorage.setItem("jumpToBooking", "true");
    console.log("jumpToBooking", localStorage.getItem("jumpToBooking"));
  }
  if (from.query.jumpToBooking == "true" && from.name == "LoginEmail") {
    // console.log('走2==========================',);
    if (from.query.bookingType) {
      if (from.query.bookingType == "Desks" || from.query.bookingType == "desks") {
        // if (localStorage.getItem('client') == 'NatWest' || localStorage.getItem('client') == 'Clorox' || localStorage.getItem('client') == 'WarnerMedia' || localStorage.getItem('client') == 'CX Campus'|| localStorage.getItem('client') == 'Rivian') {
        if (displayDesksOffice) {
          store.commit("bookingType/setBookingType", "Desks / Offices");
        } else {
          store.commit("bookingType/setBookingType", "Desks");
        }
      } else if (from.query.bookingType == "Meetings" || from.query.bookingType == "meetings") {
        store.commit("bookingType/setBookingType", "Meetings");
      }
    }
    localStorage.setItem("jumpToBooking", "true");
    console.log("jumpToBooking", localStorage.getItem("jumpToBooking"));
  }

  // 为什么要加这个
  //因为不知道为什么beforeEach这里会执行两次，所以走到======有token======这一步，
  // 会重新再走到console.log("====参数没有token =====")，（现在还不知道为什么），所以在localStorage加了这玩意，但
  // 是这个貌似会影响到其它东西....先这样吧

  if (to.query.token != "" && to.query.token != undefined) {
    localStorage.setItem("token", to.query.token);
  }

  if (to.query.cmsAdmin == "true" && to.name == "LoginEmail") {
    localStorage.setItem("cmsAdmin", "true");
  }
  if (from.query.cmsAdmin == "true" && from.name == "LoginEmail") {
    localStorage.setItem("cmsAdmin", "true");
  }

  // checkIn email link(用户会收到一个checkIn的email，点开后会跳转到space界面，然后弹出checkIn的pop up)
  if (to.query.checkInDeskId) {
    localStorage.setItem("checkInDeskId", to.query.checkInDeskId);
  }
  if (to.query.checkInMeetingId) {
    localStorage.setItem("checkInMeetingId", to.query.checkInMeetingId);
  }
  if (from.query.checkInDeskId) {
    localStorage.setItem("checkInDeskId", from.query.checkInDeskId);
  }
  if (from.query.checkInMeetingId) {
    localStorage.setItem("checkInMeetingId", from.query.checkInMeetingId);
  }

  console.log("==== !!!!!!!!!!!!!!!!!!!!!!!!!!=====", localStorage.token);
  // 这里判断用户是否登录，验证本地存储是否有token
  if (!localStorage.token || localStorage.token == null) {
    // 判断当前的token是否存在
    console.log("====localStorage没有token =====");
    if (to.query.token) {
      console.log("====参数有token =====");
      // next()
      next({
        path: "/mec"
      });
    } else {
      console.log("====参数没有token =====")
      if (to.path === '/pages/loginEmail') { // 如果是登录页面的话，直接next()
        console.log("==== 是登录页=====")
        console.log('to:', to);
        next();
      } else { // 否则 跳转到登录页面
        console.log("==== 不是登录页 跳转到登录页=====")
        console.log("====to =====", to)
        console.log("====from =====", from)
        if (to.name == 'Login') {
          console.log("====zou 1 =====",)
          next();
        } else {
          console.log("====zou 2 =====",)
          next({
            path: '/pages/loginEmail'
          });
        }
      }
    }
  } else {
    console.log("====有token =====", localStorage.token);
    if (to.name == "mec") {
      localStorage.workingHourStartTime = "";
      localStorage.workingHourEndTime = "";
      localStorage.showDeskButton = true;
      localStorage.showRoomButton = true;

      // 其它页面跳转到mec页面清空meetingIdMap，mec刷新当前页面不清空，或则从loginEmail和login跳转进来也不清空，然后进行判断，
      // 如果有meetingIdMap直接跳转到Space页面
      if (!(from.name == "LoginEmail" || from.name == "Login" || from.name == null)) {
        // 执行了!!!!!!!!!!!!
        localStorage.meetingIdMap = "";
        localStorage.timezone = "";
      }
    }
    if (to.name === "Dashboard" || to.name === "LoginEmail") {
      let unauthorized = JSON.parse(localStorage.getItem("unauthorized"));
      if (unauthorized) {
        if (to.name == "LoginEmail") {
          next();
        } else {
          next({
            path: "/pages/loginEmail"
          });
        }
        localStorage.removeItem("unauthorized");
      } else {
        if (to.name == "mec") {
          next();
        } else {
          next({
            path: "/mec"
          });
        }
      }
    } else {
      next();
    }
  }

  // 2022/1/4 前的版本
  // if (to.matched.some(record => record.meta.requiresAuth)) {
  //   console.log("==== 111111=====",)
  //   console.log("====record=====",record)
  //   // 这里判断用户是否登录，验证本地存储是否有token
  //   if (!localStorage.token) { // 判断当前的token是否存在
  //     console.log("====没有token =====",)
  //     if  (to.query.token) {
  //       next()
  //     } else {
  //       next({
  //         path: '/pages/loginEmail',
  //         // query: {redirect: to.fullPath}
  //       });
  //     }
  //
  //     // next()
  //   }
  //   else {
  //     console.log("====有token =====",)
  //     console.log("====to =====",to)
  //     console.log("====from =====",from)
  //     if (to.name == 'mec') {
  //       localStorage.workingHourStartTime = '';
  //       localStorage.workingHourEndTime = '';
  //       // 其它页面跳转到mec页面清空meetingIdMap，mec刷新当前页面不清空，或则从loginEmail和login跳转进来也不清空，然后进行判断，
  //       // 如果有meetingIdMap直接跳转到Space页面
  //       if (!(from.name == 'LoginEmail' || from.name == 'Login' || from.name == null)) {
  //         // 执行了!!!!!!!!!!!!
  //         localStorage.meetingIdMap = '';
  //       }
  //     }
  //     if (to.name === 'Dashboard') {
  //       next({
  //         path: '/mec',
  //         // query: {redirect: to.fullPath}
  //       });
  //     } else {
  //       next()
  //     }
  //   }
  // }
  // else {
  //   console.log("==== 2222222=====",)
  //   next() // 确保一定要调用 next()
  // }
});

router.afterEach((to, from, next) => {
  // console.log("ga",ga)
  // ga('set', 'page', to.path)
  // ga('send', 'pageview')
  let eventCategory = to.path;
  let eventLabel = to.name ? to.name : to.path;
  let id = localStorage.getItem("googleAnaId");
  // window.gtag('config', 'page_view', {
  //   page_title: eventLabel,
  //   page_path: eventCategory,
  //   send_to: id
  //   // send_to: this.GA_TRACKING_ID
  // })

  // if (localStorage.client == "NatWest" || localStorage.client == "Metlife") {
  //   console.log('window.gtag:', window.gtag);
  //   console.log('id:', id);
  //   // 不生效，不知道为什么
  //   if (window.gtag) {
  //     console.log('eventLabel:', eventLabel);
  //     console.log('eventCategory:', eventCategory);
  //     window.gtag("event", "page_view", {
  //       event_category: eventLabel,
  //       event_label: eventCategory,
  //       send_to: localStorage.getItem("googleAnaId") || "",
  //       dimension1: localStorage.getItem("campusName") || "",
  //     });
  //   }
  // }
});
// if (localStorage.client == "NatWest") {
//   router.afterEach((to, from, next) => {
//     console.log("to:",to)
//     // window.gtag('event', eventAction, {
//     //   event_category: eventCategory,
//     //   event_label: eventLabel,
//     //   value: eventValue,
//     //   send_to: this.GA_TRACKING_ID
//     // })
//   })
// }

new Vue({
  router,
  store,
  vuetify,
  axios,
  i18n,
  data: function() {
    return {
      ORDERID: "PLDxxxxxx0001"
    };
  },
  created() {
    Vue.prototype.getClient();
    Vue.prototype.getConfig();
    // Vue.prototype.getGid();
    // Vue.prototype.getDomain();
    // const _this = this;
    // let url = "";
    // url = "/m/campus/maps";
    // if (localStorage.meetingIdMap) {
    //   url += `?meetingId=${localStorage.meetingIdMap}`;
    // }
    // axios
    //   .get(url)
    //   .then((response) => {
    //     localStorage.mapData = JSON.stringify(response.data.data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  },
  render: h => h(App)
}).$mount("#app");
